<template>
  <div class="memeberdatail_box" style="margin-top: 10px;">
    <el-card>
      <div class="box_main">
        <div class="main_top_img">
          <div class="main_top_left">
            <span
              v-show="allData.combineOrderStatus!='已完成'&&allData.combineOrderStatus!=='已关闭'">{{allData.combineOrderStatus}}</span>
            <span v-show="allData.combineOrderStatus==='已完成'">{{
            allData.combineOrderStatus==='已完成'?'待评价':allData.combineOrderStatus}}</span>
            <span v-show="allData.combineOrderStatus==='已关闭'">{{
            allData.combineOrderStatus==='已关闭'?'已完成':allData.combineOrderStatus}}</span>
            <div class="dashed"></div>
          </div>
          <div class="main_top_right">
            <!-- // -->
            <div class="order_box">
              <div class="box_order" :style="
                allData.combineOrderStatus === '待支付'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '待发货'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已发货'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已完成'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已关闭'
                  ? 'color:#FB6A57'
                  : 'color:#999999'
              ">
                <div class="order_dotted_left"></div>
                <div class="order_icon iconfont icon-tijiaodingdan"></div>
                <div class="order_dotted_left">---------------</div>
              </div>
              <div class="submit_order">提交订单</div>
              <div class="submit_time">{{ timer.createTime }}</div>
            </div>
            <div class="order_box">
              <div class="box_order" :style="
                allData.combineOrderStatus === '待发货'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已发货'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已完成'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已关闭'
                  ? 'color:#FB6A57'
                  : 'color:#999999'
              ">
                <div class="order_dotted_left">---------------</div>
                <div class="order_icon iconfont">
                  <img style="width: 40px;height: 40px;" v-if="allData.combineOrderStatus==='待发货'||allData.combineOrderStatus === '已完成'||allData.combineOrderStatus === '已发货'
                  ||allData.combineOrderStatus === '待评价'||allData.combineOrderStatus === '已关闭'"
                    src="../../assets/images/paymentRed.png">
                  <img style="width: 40px;height: 40px;" v-else src="../../assets/images/payment.png">
                </div>
                <div class="order_dotted_left">---------------</div>
              </div>
              <div class="submit_order">用户付款成功</div>
              <div class="submit_time">{{ timer.paymentTime }}</div>
            </div>
            <div class="order_box">
              <div class="box_order" :style="
                allData.combineOrderStatus === '已发货'
                  ? 'color:#FB6A57'
                  : allData.combineOrderStatus === '已完成'
                  ? 'color:#FB6A57'
                    : allData.combineOrderStatus === '待发货'?
                    'color:#FB6A57'
                    : allData.combineOrderStatus === '已关闭'
                    ? 'color:#FB6A57'
                    :allData.combineOrderStatus === '无效订单'?
                    'color:#999999'
                    :allData.combineOrderStatus === '已取消'?
                    'color:#999999'
                    :allData.combineOrderStatus === '已发货'?
                    'color:#999999'
                    : '#999999'
              ">
                <div class="order_dotted_left">---------------</div>
                <div class="order_icon iconfont icon-yifahuo"></div>
                <div class="order_dotted_left">---------------</div>
              </div>
              <div class="submit_order">商家发货</div>
              <div class="submit_time">{{ timer.deliveryTime }}</div>
            </div>
            <div class="order_box">
              <div class="box_order" :style="
                allData.combineOrderStatus === '已关闭' ?
                 'color:#FB6A57'
                : allData.combineOrderStatus === '已完成'?
                'color:#FB6A57'
                : allData.combineOrderStatus === '待发货'?
                'color:#999999'
                :allData.combineOrderStatus === '已发货'?
                'color:#999999'
                :allData.combineOrderStatus === '已取消'?
                'color:#999999'
                :allData.combineOrderStatus === '无效订单'?
                'color:#999999'
               :'#999999'
              ">
                <div class="order_dotted_left">---------------</div>
                <div class="order_icon iconfont icon-shouye"></div>
                <div class="order_dotted_left"></div>
              </div>
              <div class="submit_order">完成</div>
              <div class="submit_time">{{ timer.receiveTime }}</div>
            </div>
          </div>
        </div>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">基本信息</div>
        </div>
        <el-table :data="tableData" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="combineOrderNo" label="订单号" width="">
          </el-table-column>
          <el-table-column prop="mobile" label="用户账号" width="">
          </el-table-column>
          <el-table-column prop="nickName" label="用户昵称" width="">
          </el-table-column>
          <el-table-column label="订单来源" width="">
            <template #default="scope">
              <div v-if="scope.row.sourceType==0">{{'PC端'}}</div>
              <div v-if="scope.row.sourceType==1">{{'APP'}}</div>
              <div v-if="scope.row.sourceType==2">{{'小程序'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="支付方式" width="">
            <template #default="scope">
              <div v-if="scope.row.payType==0">{{'未支付'}}</div>
              <div v-if="scope.row.payType==1">{{'白条'}}</div>
              <div v-if="scope.row.payType==2">{{'支付宝'}}</div>
              <div v-if="scope.row.payType==3">{{'微信'}}</div>
              <div v-if="scope.row.payType==4">{{'农行'}}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">收货人信息</div>
        </div>
        <el-table :data="shrList" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="receiverName" label="收货人" width="">
          </el-table-column>
          <el-table-column prop="receiverPhone" label="收货手机号" width="">
          </el-table-column>
          <el-table-column prop="" label="收货地址" width="">
            <template #default="scope">
              <div>
                {{ scope.row.receiverCity }}
                {{ scope.row.receiverProvince }}
                {{ scope.row.receiverRegion }}
                {{ scope.row.receiverDetailAddress }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">商品信息</div>
        </div>
        <div v-for="(item, index) in allData.orderList" :key="index" style="margin-bottom: 20px">
          <div class="table">
            <div class="table_one">订单号{{ item.orderNo}}</div>
            <div class="table_two">{{ item.storeName }}</div>
            <!-- <span style="color: red;margin-left: 20px;">
				分账已转自营
			</span> -->
          </div>
          <el-table :data="item.goodsList" border style="margin-left: 40px; margin-top: 10px"
            :header-cell-style="tableHeaderColor" size="medium">
            <el-table-column label="商品图片" width="">
              <template #default="scope">
                <img class="goodsimg" :src="scope.row.mainImageUrl" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" class="longtext" label="商品名称" width="">
            </el-table-column>
            <el-table-column label="规格" width="">
              <template #default="scope">
                <span>{{scope.row.bulkUnit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="meterUnit" class="longtext" label="计量单位" width="">
            </el-table-column>
            <el-table-column prop="quality" class="longtext" label="商品重量" width="">
            </el-table-column>
            <el-table-column prop="basicUnit" class="longtext" label="基本单位" width="">
            </el-table-column>
            <el-table-column prop="goodsNum" label="数量" width="">
            </el-table-column>
            <el-table-column prop="goodsPriceStr" label="单价" width="">
            </el-table-column>
            <el-table-column prop="totalPriceStr" label="小计" width="">
            </el-table-column>
            <el-table-column prop="invokeName" label="归属人" width="">
            </el-table-column>
          </el-table>
          <div class="table_bottom">
            <div class="bottom_orderzi">订单备注：</div>
            <div class="bottom_btn">
              <el-input :value='item.remarks' disabled placeholder="请输入内容"></el-input>
            </div>
            <div class="bottom_goods">
              <div class="goods_total">合计：</div>
              <div class="goods_money">￥{{ item.totalAmount/100 }}</div>
            </div>
          </div>
        </div>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">费用信息</div>
        </div>
        <el-table :data="Price" border style="margin-left: 40px; margin-top: 10px" :header-cell-style="tableHeaderColor"
          size="medium">
          <el-table-column prop="totalAmountStr" label="商品合计" width="">
          </el-table-column>
          <el-table-column prop="freightAmountStr" label="运费" width=""></el-table-column>
          <el-table-column prop="couponAmountStr" label="优惠券" width="">
          </el-table-column>
          <el-table-column prop="integrationAmountStr" label="积分抵扣" width="">
          </el-table-column>
          <el-table-column prop="promotionAmountStr" label="活动优惠" width="">
          </el-table-column>
          <el-table-column prop="payAmountStr" label="应付金额" width="">
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="部分退款" :visible.sync="centerDialogVisible" width="35%" :modal-append-to-body='false'>
        <el-form label-width="80px" :rules="cancelRules" :model="cancelForm" ref="cancelForm">
          <el-form-item prop="refundReason" label="退款原因">
            <el-input v-model="cancelForm.refundReason" style="width: 320px;"></el-input>
          </el-form-item>
          <div>
            <div style="display: inline-block;">
              <el-form-item prop="costAmount" label="退款金额">
                <el-input v-model="cancelForm.costAmount" placeholder="成本金额">
                </el-input>
              </el-form-item>
            </div>
            <div style="display: inline-block;">
              <el-form-item prop="storeAmount">
                <el-input v-model="cancelForm.storeAmount" placeholder="平台抽成"></el-input>
              </el-form-item>
            </div>
            <div style="display: inline-block;">
              <el-form-item prop="freightAmount">
                <el-input v-model="cancelForm.freightAmount" placeholder="运费金额"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div>
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="cancelBtn('cancelForm')">确 定</el-button>
        </div>
      </el-dialog>

      <div class="foot">
        <el-button v-show="allData.combineOrderStatus=='待发货'" type="primary" @click="Cancel">取消订单</el-button>
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>

    </el-card>
    <el-dialog title="取消订单" :visible.sync="dialogFormVisible" width="35%" :modal-append-to-body="false">
      <el-form :model="cancel" label-width="100px" label-position="right" :rules="rules" ref="inserform">
        <el-form-item label="取消原因" prop="reason">
          <el-input type="textarea" placeholder="请输入内容" v-model="cancel.reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCancel('inserform')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {
  orderDetail,
  orderCancel,
  orderPartRefund,
  transferStoreCashOut
} from "../../api/order";
import BigNumber from "bignumber.js";
import debounce from "../../utils/debounce"
export default {
  data() {
    const costAmount = (rule, value, callback) => {
      let sum = parseFloat(this.cancelForm.costAmount == "" ? 0 : this.cancelForm.costAmount) + parseFloat(this
        .cancelForm.storeAmount == "" ? 0 : this.cancelForm.storeAmount) + parseFloat(this.cancelForm
          .freightAmount == "" ? 0 : this.cancelForm.freightAmount)
      // 图片验证
      if (this.cancelForm.costAmount == '' && this.cancelForm.freightAmount == '' && this.cancelForm.storeAmount ==
        '') {
        callback(new Error("退款金额至少填写一项"));
      } else if (value > parseFloat(this.costAmount)) {
        callback(new Error(`成本金额最多可退${this.costAmount}元`));
      } else if (sum < 0) {
        callback(new Error("退款金额不能<0"));
      } else {
        callback();
      }
    };
    const storeAmount = (rule, value, callback) => {
      let str = this.floatSub(this.payAmount, this.costAmount)
      let str2 = this.floatSub(str, this.freightAmount)
      let sum = parseFloat(str2)
      if (this.cancelForm.costAmount == '' && this.cancelForm.freightAmount == '' && this.cancelForm.storeAmount ==
        '') {
        callback(new Error("退款金额至少填写一项"));
      } else if (value > parseFloat(str2)) {
        callback(new Error(`平台抽成最多可退${parseFloat(sum)}元`));
        // callback();
      } else if (parseFloat(sum) < 0) {
        callback(new Error("退款金额不能≤0"));
      } else {
        callback();
      }
    };
    const freightAmount = (rule, value, callback) => {
      let sum = parseFloat(this.cancelForm.costAmount == "" ? 0 : this.cancelForm.costAmount) + parseFloat(this
        .cancelForm.storeAmount == "" ? 0 : this.cancelForm.storeAmount) + parseFloat(this.cancelForm
          .freightAmount == "" ? 0 : this.cancelForm.freightAmount)
      if (this.cancelForm.costAmount == '' && this.cancelForm.freightAmount == '' && this.cancelForm.storeAmount ==
        '') {
        callback(new Error("退款金额至少填写一项"));
      } else if (value > parseFloat(this.freightAmount)) {
        callback(new Error(`运费最多可退${this.freightAmount}元`));
        // callback();
      } else if (sum < 0) {
        callback(new Error("退款金额不能<0"));
      } else {
        callback();
      }
    };
    return {
      dialogFormVisible: false,
      rules: {
        reason: [{
          required: true,
          message: "请填写取消原因",
          trigger: "blur"
        },],
      },
      cancelRules: {
        refundReason: [{
          required: true,
          message: "请填写退款原因",
          trigger: "blur"
        },],
        costAmount: [{
          validator: costAmount,
        },],
        storeAmount: [{
          validator: storeAmount,
        },],
        freightAmount: [{
          validator: freightAmount,
        },],
      },
      cancel: { //取消订单
        id: "", //
        reason: ""
      },
      costAmount: "",
      freightAmount: "",
      storeAmount: "",
      payAmount: "",
      cancelForm: { //部分退款
        memberId: null, //会员id
        combineOrderId: "", //总订单id
        costAmount: "", //成本金额
        freightAmount: "", //运费金额
        refundAmount: "", //退款金额
        orderId: "", //子订单id
        refundType: 3, //退款类型
        storeAmount: "", //平台抽成金额
        refundReason: "", //退款原因
        refundId: "",
        storeId: '',
        refundNo: '',
        id: ''
      },
      centerDialogVisible: false,
      tableData: [{
        memberId: "",
        combineOrderNo: "",
        mobile: "",
        nickName: "",
        sourceType: "",
        payType: "",
      },],
      Price: [{
        totalAmountStr: "",
        freightAmountStr: "",
        couponAmountStr: "",
        integrationAmountStr: "",
        promotionAmountStr: "",
        payAmountStr: "",
      },], //金额
      allData: {},
      goodsList: [],
      orderList: [], // 订单信息
      member: {}, //会员信息
      memberList: [],
      shrList: [],
      timer: {
        createTime: "", //初始化时间
        paymentTime: "", //支付时间
        deliveryTime: "", //发货时间
        receiveTime: "", //结束时间
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    floatSub(arg1, arg2) {//精确计算
      var r1, r2, m, n;
      try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2));
      //动态控制精度长度
      n = (r1 >= r2) ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    cancelBtn(formName) {
      let cancelForm = { //部分退款
        memberId: this.cancelForm.memberId, //会员id
        combineOrderId: this.cancelForm.combineOrderId, //总订单id
        costAmount: this.cancelForm.costAmount * 100, //成本金额
        freightAmount: this.cancelForm.freightAmount * 100, //运费金额
        refundAmount: this.cancelForm.refundAmount * 100, //退款金额
        orderId: this.cancelForm.orderId, //子订单id
        refundType: 3, //退款类型
        storeAmount: this.cancelForm.storeAmount, //平台抽成金额
        refundReason: this.cancelForm.refundReason, //退款原因
        refundId: this.cancelForm.refundId,
        storeId: this.cancelForm.storeId,
        refundNo: this.cancelForm.refundNo,
        id: this.cancelForm.id
      }
      cancelForm.costAmount = this.cancelForm.costAmount * 100
      cancelForm.freightAmount = this.cancelForm.freightAmount * 100
      cancelForm.storeAmount = this.cancelForm.storeAmount * 100
      cancelForm.refundAmount = parseFloat(cancelForm.costAmount) + parseFloat(cancelForm.freightAmount) +
        parseFloat(cancelForm.storeAmount)

      this.$refs[formName].validate((valid) => {
        if (valid) {
          orderPartRefund(cancelForm).then(res => {
            if (res.status == 10000) {
              this.$notify({
                title: "部分退款",
                message: "退款成功",
                type: "success",
              });
              this.centerDialogVisible = false
            }
          })
        } else {
          return false;
        }
      });

    },
    // table
    tableHeaderColor({
      rowIndex
    }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;font-weight: 700;";
      }
    },
    //获取详情数据
    list() {
      let id = this.$route.query.id;
      orderDetail({
        id
      }).then((res) => {
        this.allData = res.data;
        this.orderList = res.data.orderList;
        this.member = res.data.member;
        this.cancel.id = res.data.id
        this.storeAmount = res.data.storeAmount == null ? '0' : res.data.storeAmount
        this.cancelForm.storeAmount = res.data.storeAmount == null ? '0' : res.data.storeAmount
        this.cancelForm.refundAmount = res.data.refundAmount
        this.cancelForm.combineOrderId = res.data.id
        this.cancelForm.refundNo = res.data.combineOrderNo
        // 用户信息
        this.tableData[0].combineOrderNo = res.data.combineOrderNo;
        this.tableData[0].mobile = res.data.member.mobile;
        this.tableData[0].nickName = res.data.member.nickName;
        this.tableData[0].sourceType = res.data.sourceType;
        this.tableData[0].payType = res.data.orderList[0].payType;
        this.tableData[0].memberId = res.data.orderList[0].memberId;
        // 订单信息
        let objconsignee = {
          receiverName: this.allData.receiverName,
          receiverCity: this.allData.receiverCity,
          receiverProvince: this.allData.receiverProvince,
          receiverRegion: this.allData.receiverRegion,
          receiverDetailAddress: this.allData.receiverDetailAddress,
          receiverPhone: this.allData.receiverPhone,
        };
        this.shrList.push(objconsignee);

        // 时间信息
        this.timer.createTime = res.data.orderList[0].createTime;
        this.timer.paymentTime = res.data.orderList[0].paymentTime;
        this.timer.deliveryTime = res.data.orderList[0].deliveryTime;
        this.timer.receiveTime = res.data.orderList[0].receiveTime;

        // 金额信息
        this.Price[0].totalAmountStr = res.data.totalAmountStr
        this.Price[0].freightAmountStr = res.data.freightAmountStr;
        this.Price[0].couponAmountStr = res.data.couponAmountStr;
        this.Price[0].integrationAmountStr = res.data.integrationAmountStr;
        this.Price[0].promotionAmountStr = res.data.promotionAmountStr;
        this.Price[0].payAmountStr = res.data.payAmountStr
      });
    },
    //取消订单按钮
    Cancel() {
      this.dialogFormVisible = true;

    },
    //确认消息订单
    addCancel() {
      this.$refs.inserform.validate((valid) => {
        if (valid) {
          orderCancel({
            refundData: this.cancel
          }).then((res) => {
            if (res.status == 10000) {
              this.dialogFormVisible = false;
            }
          });
        } else {
          return;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.numClass {
  display: flex;
  // justify-content: center;
}

.foot {
  width: 1200px;
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.goodsimg {
  width: 80px;
  height: 80px;
}

.memeberdatail_box {
  margin-left: 0px;
}

#red {
  color: red;
}

/deep/ .domain {
  border: 1px solid #efefef;
  width: 100%;
}

.box_main {
  width: 1200px;
  height: 100%;

  .main_top_img {
    display: flex;
    width: 1125px;
    height: 170px;

    .main_top_left {
      width: 285px;
      height: 170px;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      line-height: 170px;
      color: red;
      position: relative;

      .dashed {
        border-right: 1px dotted #999999;
        width: 1px;
        height: 60%;
        // background-color: red;
        display: inline-block;
        position: absolute;
        top: 45px;
        right: 0px;
      }
    }

    .main_top_right {
      width: 800px;
      height: 170px;
      margin-left: 50px;
      display: flex;

      .order_box {
        width: 255px;
        height: 120px;
        margin-top: 55px;

        // background-color: red;
        .box_order {
          display: flex;

          .order_dotted_left {
            width: 200px;
            margin-top: 10px;
          }

          .order_icon {
            width: 50px;
            height: 50px;
            font-size: 40px;
            text-align: center;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .submit_order {
          text-align: center;
          font-size: 14px;
        }

        .submit_time {
          margin-top: 5px;
          text-align: center;
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }

  .esal_tal {
    display: flex;

    .dids {
      margin-left: 20px;
      width: 15px;
      height: 15px;
      background-color: rgba(194, 128, 255, 1);
    }

    .didses {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .table {
    margin-top: 10px;
    width: 1198px;
    height: 40px;
    display: flex;
    margin-bottom: -10px;
    line-height: 40px;
    margin-left: 40px;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    .table_one {
      margin-left: 15px;
      font-size: 14px;
      font-weight: 700;
    }

    .table_two {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .table_bottom {
    background-color: rgb(246 248 249);
    margin-bottom: 10px;
    width: 1198px;
    height: 60px;
    display: flex;
    margin-bottom: -10px;
    line-height: 40px;
    margin-left: 40px;
    border-bottom: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    .bottom_orderzi {
      margin-left: 20px;
      line-height: 60px;
    }

    .bottom_btn {
      width: 670px;
      height: 40px;
      margin-top: 10px;
      // background-color: blue;
    }

    .bottom_goods {
      width: 400px;
      display: flex;
      line-height: 60px;

      .goods_total {
        margin-left: 200px;
      }

      .goods_money {
        color: #ff0000;
        font-weight: bold;
      }
    }
  }
}
</style>
